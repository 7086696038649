/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useEffect, useState } from "react";
import "./../route.css";
import { CiClock2 } from "react-icons/ci";
import { TfiCup } from "react-icons/tfi";
import { auth, db } from "../../utils/firebase";
import { where, query, setDoc, onSnapshot } from "firebase/firestore";
import { addDoc, collection, getDocs, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import ModalComponent from "../../Components/modal";

const GameAdmin = () => {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const dateString = `${day}-${month}-${year}`;

  const [modalStatus, setModalStatus] = useState(false)
  const [modalResult, setModalResult] = useState(false)
  const [indexValue, setIndexValue] = useState()
  const [gameState, setGameState] = useState()
  const [gameID, setGameID] = useState()
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedNumber, setSelectedNumber] = useState("");
  const [singleResult, setSingleResult] = useState("");
  const [selectedDate, setSelectedDate] = useState(dateString);
  const [currentResult, setCurrentResult] = useState()
  const [bets, setBets] = useState([])
  const [currentTResult, setCurrentTResult] = useState()
  const [combinedData, setCombinedData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0); // State to hold the total amount


  const pattiList = [
    { number: '000' },
    { number: '100' },
    { number: '200' },
    { number: '300' },
    { number: '400' },
    { number: '500' },
    { number: '600' },
    { number: '700' },
    { number: '800' },
    { number: '900' },
    { number: '127' },
    { number: '678' },
    { number: '345' },
    { number: '120' },
    { number: '789' },
    { number: '456' },
    { number: '123' },
    { number: '890' },
    { number: '567' },
    { number: '234' },
    { number: '190' },
    { number: '777' },
    { number: '444' },
    { number: '111' },
    { number: '888' },
    { number: '555' },
    { number: '222' },
    { number: '999' },
    { number: '666' },
    { number: '333' },
    { number: '280' },
    { number: '560' },
    { number: '570' },
    { number: '580' },
    { number: '590' },
    { number: '140' },
    { number: '150' },
    { number: '160' },
    { number: '170' },
    { number: '180' },
    { number: '370' },
    { number: '470' },
    { number: '480' },
    { number: '490' },
    { number: '130' },
    { number: '230' },
    { number: '330' },
    { number: '340' },
    { number: '350' },
    { number: '360' },
    { number: '460' },
    { number: '380' },
    { number: '390' },
    { number: '670' },
    { number: '680' },
    { number: '690' },
    { number: '240' },
    { number: '250' },
    { number: '260' },
    { number: '270' },
    { number: '550' },
    { number: '290' },
    { number: '660' },
    { number: '238' },
    { number: '248' },
    { number: '258' },
    { number: '268' },
    { number: '278' },
    { number: '288' },
    { number: '450' },
    { number: '235' },
    { number: '119' },
    { number: '129' },
    { number: '139' },
    { number: '149' },
    { number: '159' },
    { number: '169' },
    { number: '179' },
    { number: '189' },
    { number: '199' },
    { number: '118' },
    { number: '137' },
    { number: '237' },
    { number: '337' },
    { number: '347' },
    { number: '357' },
    { number: '367' },
    { number: '377' },
    { number: '116' },
    { number: '117' },
    { number: '578' },
    { number: '236' },
    { number: '336' },
    { number: '157' },
    { number: '158' },
    { number: '799' },
    { number: '448' },
    { number: '467' },
    { number: '233' },
    { number: '469' },
    { number: '145' },
    { number: '146' },
    { number: '246' },
    { number: '346' },
    { number: '446' },
    { number: '267' },
    { number: '899' },
    { number: '115' },
    { number: '459' },
    { number: '126' },
    { number: '479' },
    { number: '669' },
    { number: '679' },
    { number: '689' },
    { number: '699' },
    { number: '780' },
    { number: '178' },
    { number: '124' },
    { number: '125' },
    { number: '667' },
    { number: '668' },
    { number: '579' },
    { number: '255' },
    { number: '355' },
    { number: '455' },
    { number: '447' },
    { number: '790' },
    { number: '223' },
    { number: '224' },
    { number: '478' },
    { number: '299' },
    { number: '399' },
    { number: '147' },
    { number: '247' },
    { number: '266' },
    { number: '366' },
    { number: '466' },
    { number: '566' },
    { number: '477' },
    { number: '135' },
    { number: '334' },
    { number: '588' },
    { number: '228' },
    { number: '256' },
    { number: '112' },
    { number: '113' },
    { number: '358' },
    { number: '557' },
    { number: '990' },
    { number: '225' },
    { number: '488' },
    { number: '489' },
    { number: '499' },
    { number: '166' },
    { number: '356' },
    { number: '122' },
    { number: '880' },
    { number: '368' },
    { number: '134' },
    { number: '144' },
    { number: '389' },
    { number: '245' },
    { number: '688' },
    { number: '599' },
    { number: '239' },
    { number: '177' },
    { number: '114' },
    { number: '359' },
    { number: '558' },
    { number: '379' },
    { number: '226' },
    { number: '155' },
    { number: '778' },
    { number: '148' },
    { number: '338' },
    { number: '249' },
    { number: '556' },
    { number: '449' },
    { number: '369' },
    { number: '559' },
    { number: '569' },
    { number: '227' },
    { number: '138' },
    { number: '788' },
    { number: '257' },
    { number: '339' },
    { number: '259' },
    { number: '269' },
    { number: '378' },
    { number: '289' },
    { number: '677' },
    { number: '344' },
    { number: '156' },
    { number: '445' },
    { number: '220' },
    { number: '889' },
    { number: '349' },
    { number: '133' },
    { number: '440' },
    { number: '388' },
    { number: '136' },
    { number: '335' },
    { number: '110' },
    { number: '229' },
    { number: '770' },
    { number: '348' },
    { number: '457' },
    { number: '188' },
    { number: '279' },
    { number: '577' },
    { number: '244' },
    { number: '128' },
    { number: '589' },
    { number: '779' },
    { number: '167' },
    { number: '168' },
    { number: '277' },
    { number: '458' },
    { number: '468' },
    { number: '568' },
  ]




  useEffect(() => {
    const fetchData = async () => {
      const dateString = selectedDate;

      const querySnapshot = await getDocs(collection(db, dateString));
      const allData = [];
      querySnapshot.forEach((doc) => {
        allData.push({ id: doc.id, ...doc.data() });
      });

      const validData = allData.filter(item => item.gameName);
      validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

      setGameState(validData);
    };
    fetchData();
  }, [selectedDate]);

  const handleSearch = () => {
    const selectedDateObject = new Date(selectedDate); // Convert selectedDate string to Date object
    const day = selectedDateObject.getDate().toString().padStart(2, '0'); // Get day
    const month = (selectedDateObject.getMonth() + 1).toString().padStart(2, '0'); // Get month
    const year = selectedDateObject.getFullYear(); // Get year
    const selectedDateString = `${day}-${month}-${year}`; // Construct the date string in "dd-mm-yyyy" format
    setSelectedDate(selectedDateString); // Update selectedDate state
  };








  const handleStatusSubmit = async () => {
    if (selectedStatus === "") {
      console.log("Please select a status");
      return;
    }

    if (!gameID) {
      console.log("Game ID is not defined");
      return;
    }

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    const year = currentDate.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    const docRef = doc(db, dateString, gameID);
    try {
      await updateDoc(docRef, {
        gameStatus: selectedStatus
      });
      console.log(`Game status updated to ${selectedStatus}`);
      setModalStatus(false);
    } catch (error) {
      console.error("Error updating game status:", error);
    }
    setModalStatus(false)
  };
  const deleteBazi = async (id) => {
    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      const dateString = `${day}-${month}-${year}`;

      await deleteDoc(doc(db, selectedDate, id));
      console.log(`Document with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
  const getSingleResult = (number) => {
    const sum = number.split('').reduce((acc, digit) => acc + parseInt(digit), 0);
    return sum % 10;
  };

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    const result = getSingleResult(number);
    setSingleResult(result);
    handleStatusSubmit(number, result);
  };

  const handleResultChange = async () => {
    const confirmation = window.confirm("Are you sure you want to update the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      const dateString = `${day}-${month}-${year}`;
      const dateStringfrBet = `${day}/${month}/${year}`;



      // Compute the sum of the digits of the selected number
      const sumOfDigits = selectedNumber.split('').reduce((sum, digit) => sum + parseInt(digit, 10), 0);
      // Get the last digit of the sum
      const lastDigit = sumOfDigits % 10;

      console.log(lastDigit);
      const docRef = doc(db, selectedDate, gameID);
      await updateDoc(docRef, {
        result: selectedNumber,
        singleResult: lastDigit,
        declared: true
      });
      // Update the states
      setCurrentTResult(selectedNumber);
      setCurrentResult(lastDigit);

      setSelectedNumber("");
      setSingleResult("");
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const fetchBet = (gameId) => {
    if (!gameId) return;

    try {
      const q = query(collection(db, "bets"), where("gameID", "==", gameId), where("adminEmail", "==", auth.currentUser.email));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const allData = [];
        let totalAmount = 0; // Initialize total amount

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          allData.push({ id: doc.id, ...data });
          totalAmount += parseFloat(data.amount); // Sum up the total amount
        });

        const updatedCombinedData = [];
        allData.forEach((item) => {
          const existingItemIndex = updatedCombinedData.findIndex(data => data.number === item.number);
          if (existingItemIndex === -1) {
            updatedCombinedData.push({ ...item });
          } else {
            updatedCombinedData[existingItemIndex].amount += parseFloat(item.amount);
          }
        });

        setCombinedData(updatedCombinedData);
        console.log("Total Bet Amount:", totalAmount); // Log the total amount or use it as needed
        setTotalAmount(totalAmount);
      }, (error) => {
        console.error("Error fetching bets:", error);
      });

      // Unsubscribe from the listener when component unmounts
      return unsubscribe;
    } catch (error) {
      console.error("Error setting up real-time updates:", error);
    }
  };

  const deleteResult = async () => {


    const docRef = doc(db, selectedDate, gameID);
    await updateDoc(docRef, {
      result: '',
      singleResult: '',
      declared: true
    });
    // Update the states
    setCurrentTResult('');
    setCurrentResult('');

  }

  const declareSingleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);

        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          console.log("User profile data:", userProfileData);

          // Update wallet amount and win history
          const amountMultiplied = elem.amount * 9;
          const updatedWalletAmount = parseFloat(userProfileData.wallet) + amountMultiplied;
          const winHistory = userProfileData.winHistory || [];
          const updatedWinHistory = [...winHistory, {
            ...elem,
            status: "win",
            betID: elem.id,
            timestamp: new Date().toISOString()
          }];

          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount,
            winHistory: updatedWinHistory
          });

          console.log(`Updated wallet amount and win history for user ${elem.uid}`);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map(bet => {
            if (bet.betID === elem.id) {
              console.log("Updating Win");
              return { ...bet, status: "win" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
            }
            return bet;
          });

          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });

          console.log(`Updated betLog status to "win" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "win"
            });
            console.log(`Updated status to "win" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "win"
            }, { merge: true });
            console.log(`Added status "win" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };

  const reverseSingleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }
    console.log('Reversing Single');


    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);

        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          console.log("User profile data:", userProfileData);

          // Update wallet amount and win history
          const amountMultiplied = elem.amount * 9;
          const updatedWalletAmount = parseFloat(userProfileData.wallet) - amountMultiplied;
          const winHistory = userProfileData.winHistory || [];

          const updatedWinHistory = winHistory.filter(history => history.betID !== elem.id);
          console.log(updatedWinHistory);

          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount,
            winHistory: updatedWinHistory
          });

          console.log(`Updated wallet amount and win history for user ${elem.uid}`);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map(bet => {
            if (bet.betID === elem.id) {
              console.log("Updating Win to Remove ");
              return { ...bet, status: "loss" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
              return bet;
            }
          });

          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });

          console.log(`Updated betLog status to "loss" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "loss"
            });
            console.log(`Updated status to "loss" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "loss"
            }, { merge: true });
            console.log(`Added status "loss" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };


  const declareTripleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    // Check if the selected number matches the current result number
    if (currentTResult !== selectedNumber) {
      alert('Check the Selected Number and Result Number');
      return;
    }

    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentTResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
          console.log(doc.data());
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);
        const amountMultiplied = elem.amount * 100;
        console.log('Current Wallet Amount ' + elem.amount)
        console.log(elem);
        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          // Check if the bet's number matches the selected number or currentTResult
          if (elem.number !== selectedNumber && elem.number !== currentTResult) {
            console.log(`Bet number ${elem.number} does not match the selected number or current result number.`);
            continue;
          }

          console.log("User profile data:", userProfileData);

          // Update wallet amount
          const updatedWalletAmount = parseFloat(userProfileData.wallet) + amountMultiplied;
          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount
          });
          console.log(`Updated wallet amount for user ${elem.uid}: ${updatedWalletAmount}`);

          // Update win history
          const winHistory = userProfileData.winHistory || [];
          const updatedWinHistory = [...winHistory, {
            ...elem,
            status: "win",
            betID: elem.id, // Use betID instead of timestamp
            betTime: elem.time // Optionally include the bet time
          }];
          await updateDoc(refWalletUpdate, {
            winHistory: updatedWinHistory
          });
          console.log(`Updated win history for user ${elem.uid}:`, updatedWinHistory);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map((bet) => {
            if (bet.betID === elem.id) {
              console.log("Updating Win");
              return { ...bet, status: "win" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
            }
            return bet;
          });
          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });
          console.log(`Updated betLog status to "win" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "win"
            });
            console.log(`Updated status to "win" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "win"
            }, { merge: true });
            console.log(`Added status "win" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };

  const reverseTripleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }
    console.log('Reversing Triple');
    // Check if the selected number matches the current result number
    if (currentTResult !== selectedNumber) {
      alert('Check the Selected Number and Result Number');
      return;
    }

    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentTResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
          console.log(doc.data());
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);
        const amountMultiplied = elem.amount * 100;
        console.log('Current Wallet Amount ' - elem.amount)
        console.log(elem);
        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          // Check if the bet's number matches the selected number or currentTResult
          if (elem.number !== selectedNumber && elem.number !== currentTResult) {
            console.log(`Bet number ${elem.number} does not match the selected number or current result number.`);
            continue;
          }

          console.log("User profile data:", userProfileData);

          // Update wallet amount
          const updatedWalletAmount = parseFloat(userProfileData.wallet) - amountMultiplied;
          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount
          });
          console.log(`Updated wallet amount for user ${elem.uid}: ${updatedWalletAmount}`);

          // Update win history
          const winHistory = userProfileData.winHistory || [];

          const updatedWinHistory = winHistory.filter(history => history.betID !== elem.id);

          await updateDoc(refWalletUpdate, {
            winHistory: updatedWinHistory
          });
          console.log(`Updated win history for user ${elem.uid}:`, updatedWinHistory);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map((bet) => {
            if (bet.betID === elem.id) {
              console.log("Updating Loss");
              return { ...bet, status: "loss" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
              return bet;
            }
          });
          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });
          console.log(`Updated betLog status to "win" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "win"
            });
            console.log(`Updated status to "win" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "win"
            }, { merge: true });
            console.log(`Added status "win" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };
  const number1to0 = [
    { number: 0 }
    ,
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },

  ]
  const closeFunction = (modal) => {
    modal(false)
  }
  const openFunction = (modal, key) => {
    modal(true)
    setIndexValue(key)
    console.log(key);
  }


  return (
    <div className="game--wrapper">

      {
        modalResult ? <ModalComponent longHeight={true} closeModal={() => closeFunction(setModalResult)}>
          <div className="modalResultWrapper">
            <div className="modalWrapper-Heading">
              Declare Result
            </div>
            <div className="gameResult-Details">
              <div className="declared-result-fields" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <p>Declared Result : {currentResult} | {currentTResult}</p>
                <button onClick={deleteResult}>Delete Result</button>
              </div>

              <p>Amount : {totalAmount} </p>
            </div>
            <div className="resultWrap">
              <div className="singleResultWrap">
                {
                  number1to0.map((item) => {
                    const combinedInfo = combinedData.find(data => data.number === item.number);
                    const amount = combinedInfo ? combinedInfo.amount : 0;
                    return (
                      <div className="tooltip" key={item.number}>
                        <button className={`btnResult-ModalDeclare ${item.number === currentResult ? 'redBackground' : ''}  ${combinedInfo ? 'yellowBackground' : ''}`}>
                          {item.number}
                          <div className="amount-btnResult-ModalDeclare">
                            {amount}
                          </div>
                        </button>
                        {combinedInfo && (
                          <span className="tooltiptext">
                            Total Amount: {combinedInfo.amount}
                          </span>
                        )}
                      </div>
                    );
                  })
                }
              </div>
              <div className="resultDiv-grid">
                {
                  pattiList?.map((item) => {
                    const combinedInfo = combinedData.find(data => data.number === item.number);
                    const amount = combinedInfo ? combinedInfo.amount : 0;

                    return (
                      <div className="tooltip">
                        <button
                          className={`btnResult-ModalDeclare ${item.number === currentTResult ? 'redBackground' : ''} ${item.number === selectedNumber ? 'greenBackground' : ''} ${combinedInfo ? 'yellowBackground' : ''}`}
                          onClick={() => handleNumberClick(item.number)}
                        >
                          {item.number}
                          <div className="amount-btnResult-ModalDeclare">
                            {amount}
                          </div>
                        </button>
                        {combinedInfo && (
                          <span className="tooltiptext">
                            Total Amount: {combinedInfo.amount}
                          </span>
                        )}
                      </div>
                    );
                  })
                }

              </div>

              {selectedNumber && (
                <div className="result-display">
                  <p>
                    Selected Number: <span>
                      {selectedNumber}
                    </span>

                  </p>
                  <p>
                    Single Result: <span>
                      {singleResult}
                    </span>
                  </p>
                  <button onClick={handleResultChange}>Confirm</button>
                  <button style={{ backgroundColor: 'red' }} onClick={declareSingleResult}>Declare Single Winning</button>
                  <button style={{ backgroundColor: 'red' }} onClick={reverseSingleResult}>Reverse Single Winning</button>
                  <button style={{ backgroundColor: 'red' }} onClick={declareTripleResult}>Declare Triple Winning</button>
                  <button style={{ backgroundColor: 'red' }} onClick={reverseTripleResult}>Reverse Triple Winning</button>
                </div>

              )}

            </div>
          </div>
        </ModalComponent> : null
      }
      <div className="toppart-diff">
        <div className="section--29485 gameJsx">
          <p className="nameDisplay-002">Kolkata Fatafat</p>
          <input type="date" name="date" id="dateGameWrrapper" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
          <button className="search-Btn-00239" onClick={handleSearch}>Search</button>
        </div>

      </div>
      {gameState && gameState.length > 0 ? (<div className="baziWrapper">
        {
          gameState && gameState.map((item, key) => (
            <div className="baziChilds">
              <div className="baziDetails">
                <div className="baziName" style={{ marginBottom: '10px' }}>{item.gameName} - {item.fatafatName}</div>
                <div className="gameTime"><CiClock2 /> Game Time : {item.gameTimeStart} to {item.gameTimeEnd}</div>
                <div className="baziNumbers"><TfiCup />Results : {item.result} | {item.singleResult} | {item.id}</div>
              </div>
              <div className="baziActions">

                <div className="baziAction-Child">
                  {/* <button
                    onClick={() => {
                      openFunction(setModalResult, key);
                      setCurrentResult(item.singleResult);
                      setCurrentTResult(item.result);
                      setGameID(`${item.id}`);
                      console.log(`${gameID}`);
                      fetchBet(item.id);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    Make Result
                  </button> */}

                </div>

              </div>
            </div>
          ))
        }


      </div>) : (<div> NO DATA</div>)}

    </div >
  );
};

export default GameAdmin;
