/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useState, useEffect } from 'react'
import "./../route.css";
import { CiClock2 } from "react-icons/ci";
import { TfiCup } from "react-icons/tfi";
import ModalComponent from "../../Components/modal";
import { auth, db } from "../../utils/firebase";
import { where, query, getDocs } from "firebase/firestore";
import { addDoc, collection, onSnapshot } from "firebase/firestore";

const MarketAnalysisMaster = () => {

    //SETTING TODAY'S INITIAL DATE
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    // SETTING STATES
    const [modalResult, setModalResult] = useState(false)
    const [indexValue, setIndexValue] = useState()
    const [gameState, setGameState] = useState()
    const [gameID, setGameID] = useState()
    const [selectedDate, setSelectedDate] = useState(dateString);
    const [currentResult, setCurrentResult] = useState()
    const [currentTResult, setCurrentTResult] = useState()
    const [combinedData, setCombinedData] = useState([]);
    const [tripleAmount, setTotalTripleDigitAmount] = useState()
    const [singleAmount, setTotalSingleDigitAmount] = useState()
    const [gameidModal, setGameIdModal] = useState('');

    const closeFunction = (modal) => {
        modal(false)
    }
    const openFunction = (key, id) => {
        setModalResult(true);

        // Find the object in gameState array where the object's id matches the passed id
        const gameItem = gameState.find(item => item.id === id);

        if (gameItem) {
            // Set the current result using the found gameItem
            setCurrentResult(gameItem.singleResult);
            setCurrentTResult(gameItem.result);
        }

        fetchBet(id);
    };
    const number1to0 = [
        { number: '0' }
        ,
        { number: '1' },
        { number: '2' },
        { number: '3' },
        { number: '4' },
        { number: '5' },
        { number: '6' },
        { number: '7' },
        { number: '8' },
        { number: '9' },

    ]

    const pattiList = [
        { number: '000' },
        { number: '100' },
        { number: '200' },
        { number: '300' },
        { number: '400' },
        { number: '500' },
        { number: '600' },
        { number: '700' },
        { number: '800' },
        { number: '900' },
        { number: '127' },
        { number: '678' },
        { number: '345' },
        { number: '120' },
        { number: '789' },
        { number: '456' },
        { number: '123' },
        { number: '890' },
        { number: '567' },
        { number: '234' },
        { number: '190' },
        { number: '777' },
        { number: '444' },
        { number: '111' },
        { number: '888' },
        { number: '555' },
        { number: '222' },
        { number: '999' },
        { number: '666' },
        { number: '333' },
        { number: '280' },
        { number: '560' },
        { number: '570' },
        { number: '580' },
        { number: '590' },
        { number: '140' },
        { number: '150' },
        { number: '160' },
        { number: '170' },
        { number: '180' },
        { number: '370' },
        { number: '470' },
        { number: '480' },
        { number: '490' },
        { number: '130' },
        { number: '230' },
        { number: '330' },
        { number: '340' },
        { number: '350' },
        { number: '360' },
        { number: '460' },
        { number: '380' },
        { number: '390' },
        { number: '670' },
        { number: '680' },
        { number: '690' },
        { number: '240' },
        { number: '250' },
        { number: '260' },
        { number: '270' },
        { number: '550' },
        { number: '290' },
        { number: '660' },
        { number: '238' },
        { number: '248' },
        { number: '258' },
        { number: '268' },
        { number: '278' },
        { number: '288' },
        { number: '450' },
        { number: '235' },
        { number: '119' },
        { number: '129' },
        { number: '139' },
        { number: '149' },
        { number: '159' },
        { number: '169' },
        { number: '179' },
        { number: '189' },
        { number: '199' },
        { number: '118' },
        { number: '137' },
        { number: '237' },
        { number: '337' },
        { number: '347' },
        { number: '357' },
        { number: '367' },
        { number: '377' },
        { number: '116' },
        { number: '117' },
        { number: '578' },
        { number: '236' },
        { number: '336' },
        { number: '157' },
        { number: '158' },
        { number: '799' },
        { number: '448' },
        { number: '467' },
        { number: '233' },
        { number: '469' },
        { number: '145' },
        { number: '146' },
        { number: '246' },
        { number: '346' },
        { number: '446' },
        { number: '267' },
        { number: '899' },
        { number: '115' },
        { number: '459' },
        { number: '126' },
        { number: '479' },
        { number: '669' },
        { number: '679' },
        { number: '689' },
        { number: '699' },
        { number: '780' },
        { number: '178' },
        { number: '124' },
        { number: '125' },
        { number: '667' },
        { number: '668' },
        { number: '579' },
        { number: '255' },
        { number: '355' },
        { number: '455' },
        { number: '447' },
        { number: '790' },
        { number: '223' },
        { number: '224' },
        { number: '478' },
        { number: '299' },
        { number: '399' },
        { number: '147' },
        { number: '247' },
        { number: '266' },
        { number: '366' },
        { number: '466' },
        { number: '566' },
        { number: '477' },
        { number: '135' },
        { number: '334' },
        { number: '588' },
        { number: '228' },
        { number: '256' },
        { number: '112' },
        { number: '113' },
        { number: '358' },
        { number: '557' },
        { number: '990' },
        { number: '225' },
        { number: '488' },
        { number: '489' },
        { number: '499' },
        { number: '166' },
        { number: '356' },
        { number: '122' },
        { number: '880' },
        { number: '368' },
        { number: '134' },
        { number: '144' },
        { number: '389' },
        { number: '245' },
        { number: '688' },
        { number: '599' },
        { number: '239' },
        { number: '177' },
        { number: '114' },
        { number: '359' },
        { number: '558' },
        { number: '379' },
        { number: '226' },
        { number: '155' },
        { number: '778' },
        { number: '148' },
        { number: '338' },
        { number: '249' },
        { number: '556' },
        { number: '449' },
        { number: '369' },
        { number: '559' },
        { number: '569' },
        { number: '227' },
        { number: '138' },
        { number: '788' },
        { number: '257' },
        { number: '339' },
        { number: '259' },
        { number: '269' },
        { number: '378' },
        { number: '289' },
        { number: '677' },
        { number: '344' },
        { number: '156' },
        { number: '445' },
        { number: '220' },
        { number: '889' },
        { number: '349' },
        { number: '133' },
        { number: '440' },
        { number: '388' },
        { number: '136' },
        { number: '335' },
        { number: '110' },
        { number: '229' },
        { number: '770' },
        { number: '348' },
        { number: '457' },
        { number: '188' },
        { number: '279' },
        { number: '577' },
        { number: '244' },
        { number: '128' },
        { number: '589' },
        { number: '779' },
        { number: '167' },
        { number: '168' },
        { number: '277' },
        { number: '458' },
        { number: '468' },
        { number: '568' },
    ]
    useEffect(() => {
        const fetchData = () => {
            const dateString = selectedDate;

            const q = collection(db, dateString);

            onSnapshot(q, (querySnapshot) => {
                const allData = [];
                querySnapshot.forEach((doc) => {
                    allData.push({ id: doc.id, ...doc.data() });
                });

                const validData = allData.filter(item => item.gameName);
                validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

                setGameState(validData);
            }, (error) => {
                console.error("Error fetching data:", error);
            });
        };

        if (selectedDate) {
            fetchData();
        }
    }, [selectedDate]);
    const handleSearch = () => {
        const selectedDateObject = new Date(selectedDate); // Convert selectedDate string to Date object
        const day = selectedDateObject.getDate().toString().padStart(2, '0'); // Get day
        const month = (selectedDateObject.getMonth() + 1).toString().padStart(2, '0'); // Get month
        const year = selectedDateObject.getFullYear(); // Get year
        const selectedDateString = `${day}-${month}-${year}`; // Construct the date string in "dd-mm-yyyy" format
        setSelectedDate(selectedDateString); // Update selectedDate state
    };

    const fetchBet = async (gameId) => {
        if (!gameId) return;
        setGameIdModal(gameId)
        try {
            const userEmail = auth.currentUser.email;

            // Query bets where adminEmail matches the current user's email and gameID matches the provided gameId
            const betsQuery = query(
                collection(db, "bets"),
                where("masterEmail", "==", userEmail),
                where("gameID", "==", gameId)
            );

            const betsSnapshot = await getDocs(betsQuery);

            const allData = betsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                number: doc.data().number.toString() // Ensure number is a string
            }));

            const updatedCombinedData = allData.reduce((acc, item) => {
                const existingItemIndex = acc.findIndex(data => data.number === item.number);
                if (existingItemIndex === -1) {
                    acc.push({ ...item });
                } else {
                    acc[existingItemIndex].amount += parseFloat(item.amount);
                }
                return acc;
            }, []);

            setCombinedData(updatedCombinedData);

            // Calculate total amount played
            const totalAmount = updatedCombinedData.reduce((total, item) => total + item.amount, 0);

            // Calculate total amount for triple-digit numbers
            const totalTripleDigit = updatedCombinedData
                .filter(item => /^\d{3}$/.test(item.number)) // Ensure number is treated as a string for regex
                .reduce((total, item) => total + item.amount, 0);
            setTotalTripleDigitAmount(totalTripleDigit);

            // Calculate total amount for single-digit numbers
            const totalSingleDigit = updatedCombinedData
                .filter(item => /^\d$/.test(item.number)) // Ensure number is treated as a string for regex
                .reduce((total, item) => total + item.amount, 0);
            setTotalSingleDigitAmount(totalSingleDigit);

        } catch (error) {
            console.error("Error fetching bets:", error);
        }
    };







    return (
        <div className="game--wrapper">
            {
                modalResult ? (
                    <ModalComponent longHeight={true} closeModal={() => closeFunction(setModalResult)}>
                        <div className="modalResultWrapper">
                            <div className="modalWrapper-Heading">
                                View Chart
                            </div>
                            <div className="resultWrap">
                                <div className="singleResultWrap">
                                    {
                                        number1to0
                                            .filter(item => item.number.toString().length === 1) // Filter for single-digit numbers
                                            .map((item) => {
                                                const combinedInfo = combinedData.find(data => data.number === item.number);
                                                const amount = combinedInfo ? combinedInfo.amount : 0;
                                                return (
                                                    <div className="tooltip" key={item.number}>
                                                        <button className={`btnResult-ModalDeclare ${item.number === currentResult ? 'redBackground' : ''} ${amount > 0 ? 'yellowBackground' : ''}`}>
                                                            {item.number}
                                                            <div className="amount-btnResult-ModalDeclare">
                                                                {amount}
                                                            </div>
                                                        </button>
                                                        {combinedInfo && (
                                                            <span className="tooltiptext">
                                                                Total Amount: {combinedInfo.amount}
                                                                <div>Total Triple Digit Amount: {tripleAmount}</div>
                                                                <div>Total Single Digit Amount: {singleAmount}</div>
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            })
                                    }

                                </div>
                                <div className="resultDiv-grid">
                                    {
                                        pattiList?.map((item) => {
                                            const combinedInfo = combinedData.find(data => {
                                                const numberStr = data.number.toString();
                                                return numberStr.length === 3 && numberStr === item.number.toString();
                                            });
                                            const amount = combinedInfo ? combinedInfo.amount : 0;
                                            return (
                                                <div className="tooltip" key={item.number}>
                                                    <button
                                                        className={`btnResult-ModalDeclare ${item.number === currentTResult ? 'redBackground' : ''} ${combinedInfo ? 'yellowBackground' : ''}`}
                                                    >
                                                        {item.number}
                                                        <div className="amount-btnResult-ModalDeclare">
                                                            {amount}
                                                        </div>
                                                    </button>
                                                    {combinedInfo && (
                                                        <span className="tooltiptext">
                                                            Total Amount: {combinedInfo.amount}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                ) : null
            }
            <div className="toppart-diff">
                <div className="section--29485">
                    <p className="nameDisplay-002">Kolkata Fatafat</p>
                    <input type="date" name="date" id="dateGameWrrapper" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                    <button className="search-Btn-00239" onClick={handleSearch}>Search</button>
                </div>
            </div>
            {gameState.length > 0 ? (
                <>
                    <div className="baziWrapper">
                        {/* First Wrapper: Filter by fatafatName 'X' */}
                        <h2>Kolkata Fatafat</h2>
                        {gameState
                            .filter(item => item.fatafatName === 'kolkatafatafat') // Replace 'X' with the specific name you want to filter by
                            .map((item, key) => (
                                <div className="baziChilds" key={key}>
                                    <div className="baziDetails">
                                        <div className="baziName" style={{ marginBottom: "10px" }}>{item.gameName}</div>
                                        <div className="gameTime"><CiClock2 /> Game Time : {item.gameTimeStart} to {item.gameTimeEnd}</div>
                                        <div className="baziNumbers"><TfiCup /> Results : {item.result} | {item.singleResult} | {item.id}</div>
                                    </div>
                                    <div className="baziActions">
                                        <button onClick={() => openFunction(key, item.id)}>
                                            Analysis
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="baziWrapper">
                        <h2>Dear Fatafat</h2>
                        {/* Second Wrapper: Filter by fatafatName 'Y' */}
                        {gameState
                            .filter(item => item.fatafatName === 'kalyanifatafat') // Replace 'Y' with the specific name you want to filter by
                            .map((item, key) => (
                                <div className="baziChilds" key={key}>
                                    <div className="baziDetails">
                                        <div className="baziName" style={{ marginBottom: "10px" }}>{item.gameName}</div>
                                        <div className="gameTime"><CiClock2 /> Game Time : {item.gameTimeStart} to {item.gameTimeEnd}</div>
                                        <div className="baziNumbers"><TfiCup /> Results : {item.result} | {item.singleResult} | {item.id}</div>
                                    </div>
                                    <div className="baziActions">
                                        <button onClick={() => openFunction(key, item.id)}>
                                            Analysis
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>

                </>

            ) : (<div> NO DATA</div>)}
        </div>
    )
}

export default MarketAnalysisMaster