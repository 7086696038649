/* eslint-disable indent *//* eslint-disable linebreak-style */
/* eslint-disable quotes */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBGZKW2rzFeCG56Zh6ZJcLiakso75iYx8w",
    authDomain: "ujjwal-fatafat.firebaseapp.com",
    projectId: "ujjwal-fatafat",
    storageBucket: "ujjwal-fatafat.appspot.com",
    messagingSenderId: "227011502317",
    appId: "1:227011502317:web:0441f46064e4f573aaece4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
